import { render, staticRenderFns } from "./ProductionProvisionsExport.vue?vue&type=template&id=ac4c4bc0&scoped=true"
import script from "./ProductionProvisionsExport.vue?vue&type=script&lang=js"
export * from "./ProductionProvisionsExport.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac4c4bc0",
  null
  
)

export default component.exports